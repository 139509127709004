.app {
    display: flex;
    flex-direction: row;
    height: 100%;

    gap: 1rem;

    .sidebar {
        flex-basis: 15rem;
        margin: 1rem 0 1rem 1rem;
        display: flex;
        flex-direction: column;
        padding: 1rem;

        .logo {
            display: flex;
            flex-direction: column;
        
            gap: 10px;
        
            svg:first-child {
                margin: 0 auto;
            }
        }

        nav {
            flex: 1;
            overflow: auto;
            margin-top: 1rem;

            a {
                color: inherit;
            }
        }

        > footer { 
            margin-top: 1rem;
            text-align: center;

            img {
                max-width: 100%;
                height: auto;
            }
        }

    }

    .main {
        margin: 1rem 1rem 1rem 0;
        overflow: auto;
        flex: 1;
        border-radius: 0.5rem;
    }
}

.dashboard {

    padding: 1rem;
}

.dashboard-blurb {
    margin: 1rem;
    padding: 1rem;
}

.dashboard-tools {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: 1rem;
    margin: 1rem 1rem 0.5rem 1rem;



    a {
        text-transform: none !important;
        text-decoration: none;
        background-color: white;
        border: 1px solid silver;
        padding: 10px 20px;
        border-radius: 5px;
        transition: all 0.1s ease-in-out;
        color: inherit;

        &:hover {
            background-color: #f5f5f5;
        }
    }
}

.tool {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    text-decoration: none;

    h2 {
        margin-bottom: 1rem;
    }
}