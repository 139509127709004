import {
    BrowserRouter, Link as RouterLink,
    LinkProps as RouterLinkProps, Route, Routes, useMatches, useMatch
} from 'react-router-dom';
import styles from './App.module.scss';
import { Logo } from './Logo';
import { toolsRegistry, ToolInfo } from './toolsRegistry';
import CssBaseline from '@mui/material/CssBaseline';
import { Alert, Box, Button, Card, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, ThemeProvider, Typography, createTheme } from '@mui/material';
import { forwardRef, useMemo } from 'react';

const theme = createTheme();

export function App() {
    return <ThemeProvider theme={theme}>
        <div className={styles.app}>
            <BrowserRouter>
                <Sidebar />
                <Main />
            </BrowserRouter>
        </div>
    </ThemeProvider>
}

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
    itemProps,
    ref,
) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

interface ListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
}

function SidebarNavItem(props: ListItemLinkProps) {
    const { icon, primary, to } = props;
    const match = useMatch(to);

    return (
        <ListItem component={Link} to={to} disablePadding>
            <ListItemButton selected={!!match}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItemButton>
        </ListItem>
    );
}

export function Sidebar() {

    return <Paper className={styles.sidebar}>
        <Link to="/" className={styles.logo}>
            <Logo />
        </Link>
        <Box component="nav">
            <List>
                {toolsRegistry.map(tool => <SidebarNavItem key={tool.id} primary={tool.name} to={tool.id} />)}
            </List>
        </Box>
        <PoweredBy />
    </Paper>
}

export function Main() {
    return <Box className={styles.main}>
        <Routes>
            {toolsRegistry.map(tool => <Route key={tool.id} path={tool.id} element={<ToolPage tool={tool} />} />)}
            <Route path="/" element={<Dashboard />} />
        </Routes>
    </Box>;
}

export function Dashboard() {
    return <Paper className={styles.dashboard} data-testid="dashboard-container">
        <Typography variant="h1">On Air Toolbox</Typography>
        <Alert severity="info" className={styles['dashboard-blurb']}>
            <Typography variant="body1">
                Welcome to the ultimate broadcaster's companion—your comprehensive suite for on-air efficiency. Our suite of tools is meticulously crafted to cater to every aspect of radio broadcasting. Whether you're planning, producing, or evaluating, our versatile range of calculators and utilities are here to streamline your workflow. With over 15+ dynamic tools, we empower you to perform at your best, ensuring precision and ease with every use. Embrace the simplicity of top-tier broadcasting tools and elevate your on-air performance today.
            </Typography>
        </Alert>
        <Typography variant="h2">Tools</Typography>
        <Box className={styles['dashboard-tools']}>
            {toolsRegistry.map(tool => <Button component={Link} key={tool.id} to={tool.id} data-testid={`dashboard-button-${tool.id}`}>
                {tool.name}
            </Button>)}
        </Box>
    </Paper>;
}

export function ToolPage({ tool }: { tool: ToolInfo }) {
    const Component = tool.component;
    return <Paper className={styles.tool}>
        <Typography variant="h2">{tool.name}</Typography>
        <Component />
    </Paper>;
}

export function PoweredBy() {
    return <footer>
        <a href="https://www.playitsoftware.com" target="_blank"><img src="https://www.playitsoftware.com/Content/Images/PoweredBy/powered-by-light200.png" alt="Powered by PlayIt Software" /></a>
    </footer>
}