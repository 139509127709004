.tool {
    width: 100%;
}

.fileTypeBox, .durationBox {
    margin-bottom: 1rem;
}

.parametersBox {
    display: flex;
    flex-direction: row;
    align-items: center;  
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.duration {
    max-width: 15rem;
    width: 100%;
}

.bitDepth {
    max-width:5rem;
    width: 100%;
}


.sampleRate {
    max-width: 10rem;
    width: 100%;
}

.channelCount {
    max-width: 10rem;
    width: 100%;
}

.bitrate {
    max-width: 5rem;
    width: 100%;
}