.tool {
    textarea {
        width: 100%;
        max-width: 10rem;
        height: 10rem;

        border: none;
        outline: 1px solid silver;
        border-radius: 5px;
        padding: 0.5rem;

        font-family: inherit;
        font-size: inherit;
        color: inherit;
    }

    .textAreaBox, .answerBox {
        margin-bottom: 1rem;
    }
}