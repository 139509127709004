import { useState } from "react";
import { ToolInfo } from "../../toolsRegistry";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import styles from './AudioFileSizeCalculator.module.scss';
import { Box } from "@mui/material";

/*
    Audio File Size Calculator

    This tool takes a file type, uncompressed or compressed

    If uncompressed, it takes a bit depth, sample rate and channel count
    If compressed, it takes a bitrate

    Given a duration
    It then calculates the file size of the audio file

*/
export function AudioFileSizeCalculator() {

    const [fileType, setFileType] = useState<'uncompressed' | 'compressed'>('uncompressed');
    const [duration, setDuration] = useState<string>('00:01:23.456');
    const [bitDepth, setBitDepth] = useState<number>(16);
    const [sampleRate, setSampleRate] = useState<number>(44100);
    const [bitRate, setBitRate] = useState<number>(128);
    const [channelCount, setChannelCount] = useState<number>(2);

    function getFileSize() {
        if (!duration) {
            return '';
        }

        const durationParts = duration.split(':');
        let seconds = 0;
        let minutes = 0;
        let hours = 0;

        if (durationParts.length === 3) {
            hours = parseInt(durationParts[0]);
            minutes = parseInt(durationParts[1]);
            seconds = parseFloat(durationParts[2]);
        } else if (durationParts.length === 2) {
            minutes = parseInt(durationParts[0]);
            seconds = parseFloat(durationParts[1]);
        } else {
            seconds = parseFloat(durationParts[0]);
        }

        let fileSize = 0;
        let totalSeconds = (hours * 60 * 60) + (minutes * 60) + seconds;

        if (fileType === 'uncompressed') {
            fileSize = (totalSeconds * sampleRate * bitDepth * channelCount) / 8;
        } else {
            fileSize = (totalSeconds * bitRate * 1000) / 8;
        }

        let fileSizeStr = '';
        if (fileSize < 1024) {
            fileSizeStr = `${fileSize.toFixed(2)} bytes`;
        } else if (fileSize < 1024 * 1024) {
            fileSizeStr = `${(fileSize / 1024).toFixed(2)} KB`;
        } else if (fileSize < 1024 * 1024 * 1024) {
            fileSizeStr = `${(fileSize / 1024 / 1024).toFixed(2)} MB`;
        } else {
            fileSizeStr = `${(fileSize / 1024 / 1024 / 1024).toFixed(2)} GB`;
        }
        return fileSizeStr;
    }

    return <Box className={styles.tool}>
        <Box className={styles.fileTypeBox}>
            <FormControl variant="outlined" className={styles.fileType}>
                <InputLabel id="file-type-label">File Type</InputLabel>
                <Select label="File Type" labelId="file-type-label" value={fileType} onChange={(e) => setFileType(e.target.value as 'uncompressed' | 'compressed')}>
                    <MenuItem value="uncompressed">Uncompressed</MenuItem>
                    <MenuItem value="compressed">Compressed</MenuItem>
                </Select>
            </FormControl>
        </Box>
        <Box className={styles.durationBox}>
            <TextField className={styles.duration} label="Duration" value={duration} onChange={(e) => setDuration(e.target.value)} helperText="Format: HH:MM:SS.FFF, MM:SS.FFF or SS.FFF" />
        </Box>
        <Box className={styles.parametersBox}>
            {fileType === 'uncompressed' && (
                <>
                    <FormControl variant="outlined" className={styles.bitDepth}>
                        <InputLabel id="bit-depth-label">Bit Depth</InputLabel>
                        <Select label="Bit Depth" labelId="bit-depth-label" value={bitDepth} onChange={(e) => setBitDepth(parseInt(e.target.value as string))}>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                            <MenuItem value={32}>32</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={styles.sampleRate}>
                        <InputLabel id="sample-rate-label">Sample Rate</InputLabel>
                        <Select label="Sample Rate" labelId="sample-rate-label" value={sampleRate} onChange={(e) => setSampleRate(parseInt(e.target.value as string))}>
                            <MenuItem value={22050}>22050</MenuItem>
                            <MenuItem value={44100}>44100</MenuItem>
                            <MenuItem value={48000}>48000</MenuItem>
                            <MenuItem value={96000}>96000</MenuItem>
                            <MenuItem value={192000}>192000</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={styles.channelCount}>
                        <InputLabel id="channel-count-label">Channel Count</InputLabel>
                        <Select label="Channel Count" labelId="channel-count-label" value={channelCount} onChange={(e) => setChannelCount(parseInt(e.target.value as string))}>
                            <MenuItem value={1}>Mono</MenuItem>
                            <MenuItem value={2}>Stereo</MenuItem>
                        </Select>
                    </FormControl>
                </>
            )}
            {fileType === 'compressed' && (
                <TextField className={styles.bitrate} type="number" label="Bitrate" value={bitRate} onChange={(e) => setBitRate(parseInt(e.target.value))} helperText="kbps" />
            )}
        </Box>
        <Box className={styles.fileSizeBox}>
            <Typography variant="h6">File Size: </Typography>
            <Typography variant="body1">{getFileSize()}</Typography>
        </Box>
    </Box>
}

export const toolInfo: ToolInfo = {
    id: 'audio-file-size-calculator',
    name: 'Audio File Size Calculator',
    component: AudioFileSizeCalculator
};
