import { useEffect, useState } from "react";
import styles from './DurationCalculator.module.scss';
import { ToolInfo } from "../../toolsRegistry";
import { Box, Typography } from "@mui/material";

/*
    Duration Calculator

    This tool takes a list of durations and adds them together.

    The input format is:
    HH:MM:SS
    MM:SS
*/
export function DurationCalculator () {

    const [answer, setAnswer] = useState<number | null>(null);
    const [input, setInput] = useState<string>('01:23\r\n02:34');

    function calculateDuration (input: string): number | null {
        if (!input) {
            return null;
        }

        function parseDuration (line: string): number | null {
            const matchWithHours = line.match(/(\d+):(\d+):(\d+)/);
            if (matchWithHours) {
                const hours = parseInt(matchWithHours[1]);
                const minutes = parseInt(matchWithHours[2]);
                const seconds = parseInt(matchWithHours[3]);

                return (hours * 60 * 60) + (minutes * 60) + seconds;
            }

            const matchWithMinutes = line.match(/(\d+):(\d+)/);
            if (matchWithMinutes) {
                const minutes = parseInt(matchWithMinutes[1]);
                const seconds = parseInt(matchWithMinutes[2]);

                return (minutes * 60) + seconds;
            }

            return null;
        }

        const lines = input.split('\n');
        let total = 0;

        for (let line of lines) {
            const duration = parseDuration(line);
            if (duration) {
                total += duration;
            }
        }

        return total;
    };

    function formatDuration(duration: number | null): string {
        if(duration === null) {
            return '-';
        }

        const hours = Math.floor(duration / 60 / 60);
        const minutes = Math.floor((duration - (hours * 60 * 60)) / 60);
        const seconds = duration - (hours * 60 * 60) - (minutes * 60);

        if(hours === 0) {
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    useEffect(() => {  
        setAnswer(calculateDuration(input));
    }, [input]);

    return <Box className={styles.tool}>
        <Box className={styles.textAreaBox}>
            <textarea role="textbox" value={input} onChange={(e) => {
                setInput(e.currentTarget.value);
            }} />
        </Box>
        <Box className={styles.answerBox}>
            <Typography variant="h6">Total Duration: </Typography>
            <Typography variant="body1" data-testid="answer">{formatDuration(answer)}</Typography>
        </Box>
    </Box>
}

export const toolInfo: ToolInfo = {
    name: 'Duration Calculator',
    id: 'duration-calculator',
    component: DurationCalculator,
};