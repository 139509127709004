import * as AllTools from './tools/toolInfos';

class ToolsRegistry {
    private tools: ToolInfo[];
    constructor() {
        this.tools = [];
    }

    register(toolInfo: ToolInfo) {
        this.tools.push(toolInfo);
    }

    map<T>(callback: (tool: ToolInfo) => T) {
        return this.tools.map(callback);
    }
}

export interface ToolInfo {
    name: string;
    id: string;
    component: React.ComponentType;
}

export const toolsRegistry = new ToolsRegistry();
for(let tool of Object.values(AllTools)) {
    toolsRegistry.register(tool);
}